import { ColorType } from '@/types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Icons from 'lucide-react';
import { type LucideIcon } from 'lucide-react';
import CardSkeleton from './skeleton/CardSkeleton';

type StatCardProps = {
    icon?: LucideIcon;
    title: string;
    value: number;
    percentage?: number;
    link: string;
    iconColor?: ColorType;
    isLoading?: boolean;
};

function StatCard({
    icon,
    title,
    value,
    percentage,
    link,
    iconColor = 'primary',
    isLoading = true
}: StatCardProps) {
    const { t } = useTranslation();
    const Icon = icon;

    if (isLoading) {
        return <CardSkeleton />;
    }

    return (
        <div className="flex flex-col bg-white  rounded-xl hover:drop-shadow-sm transition-all duration-300 ease-in-out hover:bg-neutral-50  ">
            <div className="p-4 md:p-5 flex ">
                <div className="w-full">
                    <div className="flex flex-row gap-x-2 justify-start items-center mb-2 ">
                        {Icon && (
                            <Icon
                                className={`size-4 text-${iconColor}-500`}
                                color={iconColor}
                            />
                        )}

                        <p className="text-sm uppercase tracking-wide text-gray-500 ">
                            {title}
                        </p>
                    </div>

                    <div className="mt-1 flex items-end justify-center  gap-x-2">
                        <h3 className=" text-4xl leading-none  font-medium ">
                            {value}
                        </h3>
                        <span className="flex items-center gap-x-1 text-red-600">
                            {percentage !== undefined && (
                                <span className="inline-block text-lg">
                                    {percentage}%
                                </span>
                            )}
                        </span>
                    </div>
                </div>
            </div>

            <Link
                className="py-3 px-4 md:px-5 inline-flex justify-between items-center text-sm text-gray-600 border-t border-gray-200 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 rounded-b-xl "
                to={link}
            >
                {t('common.moreDetails')}
                <Icons.ChevronRightIcon className="size-4" />
            </Link>
        </div>
    );
}

export default StatCard;
