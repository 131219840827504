import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useSession } from '@/contexts'; // Adjust if needed
import setTheme from '@/utils/setTheme';
import themes from '@/theme';
import { ThemeType } from '@/types';
import { useEffect } from 'react';
import { checkRoutePermission } from '@/utils';

const ProtectedRoute = () => {
    const { session, loading, user } = useSession();
    const location = useLocation();

    // Set the theme after user data is available
    useEffect(() => {
        if (user?.merchantId !== undefined) {
            setTheme(
                themes[user.merchantId as keyof typeof themes] as ThemeType
            );
        }
    }, [user?.merchantId]);

    if (user && !checkRoutePermission(location.pathname, user)) {
        return <Navigate to="/403" />;
    }

    if (loading) return <p>Loading session...</p>;

    // Ensure we're not updating state while rendering
    return session?.access_token ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
