import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { StoreType, CreateAndEditStoreInputType } from '@/types';
import { useSession } from '../authContext/AuthContext';
import { useGlobalNotification } from '../ErrorContext';
import { useTranslation } from 'react-i18next';
import { checkPermission, serviceAPI } from '@/utils';

interface StoresContextProps {
    stores: StoreType[] | [];
    isLoading: boolean;
    isFetchingError: boolean;
    errorMessage: string | null;
    createStore: (
        newStore: Omit<CreateAndEditStoreInputType, 'storeId'>
    ) => Promise<void>;
    updateStore: (updatedStore: CreateAndEditStoreInputType) => Promise<void>;
    deleteStore: (id: string) => Promise<void>;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    pageSize: number;
    setPageSize: (pageSize: number) => void;
}

const StoreContext = createContext<StoresContextProps>({
    stores: [],
    isLoading: false,
    isFetchingError: false,
    errorMessage: null,
    createStore: async () => {},
    updateStore: async () => {},
    deleteStore: async () => {},
    currentPage: 0,
    setCurrentPage: () => {},
    pageSize: 10,
    setPageSize: () => {}
});

const useStoreQuery = (
    merchantId: string,
    access_token: string,
    setErrorMessage: (message: string) => void,
    hasStorePermission: boolean
) => {
    return useQuery<StoreType[], Error>(
        ['stores', merchantId],
        async () => {
            const response = await serviceAPI('device').get(`/stores`);
            if (response && response.status !== 200) {
                throw new Error('Failed to fetch stores');
            }

            return response.data;
        },
        {
            enabled: !!access_token && hasStorePermission,
            staleTime: Infinity, // Cache data for 5 minutes
            onError: (error: any) => {
                console.log('ERRR', error);

                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to fetch stores'
                );
            }
        }
    );
};

export const StoreProvider: React.FC<{ children: ReactNode }> = ({
    children
}) => {
    const queryClient = useQueryClient();
    const { session, user } = useSession();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { setGlobalError, setGlobalSuccess } = useGlobalNotification();
    const [currentPage, setCurrentPage] = useState(0); // Default to page 0
    const [pageSize, setPageSize] = useState(10); // Default to page size of 10
    const { t } = useTranslation();

    // Fetch stores
    const hasStorePermission = checkPermission(
        'storeManagement',
        user?.permissions
    );

    const storeQuery = useStoreQuery(
        user?.merchantId!,
        session?.access_token!,
        setErrorMessage,
        hasStorePermission
    );

    const stores = hasStorePermission ? storeQuery.data : user?.stores;
    const isLoading = hasStorePermission ? storeQuery.isLoading : false;
    const isFetchingError = hasStorePermission ? storeQuery.isError : false;

    // Create store
    const createStoreMutation = useMutation(
        async (newStore: Omit<CreateAndEditStoreInputType, 'storeId'>) => {
            await serviceAPI('device').post(`/stores`, newStore);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['stores']);
                setGlobalSuccess(t('store.storeCreateSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to create store'
                );
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to create store'
                );
            }
        }
    );

    // Update store
    const updateStoreMutation = useMutation(
        async (updatedStore: CreateAndEditStoreInputType) => {
            await serviceAPI('device').put(
                `/stores/${updatedStore.storeId}`,
                updatedStore
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['stores']);
                setGlobalSuccess(t('store.storeUpdateSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update store'
                );
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update store'
                );
            }
        }
    );

    // Delete store
    const deleteStoreMutation = useMutation(
        async (id: string) => {
            await serviceAPI('device').delete(`/stores/${id}`);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['stores']);
                setGlobalSuccess(t('store.storeDeleteSuccess'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update store'
                );
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to delete store'
                );
            }
        }
    );

    const createStore = async (
        newStore: Omit<CreateAndEditStoreInputType, 'storeId'>
    ): Promise<void> => {
        setErrorMessage(null);
        try {
            await createStoreMutation.mutateAsync(newStore);
        } catch (error: any) {
            // Error already handled in mutation
            // Error already handled in mutation
            setErrorMessage(
                error.response?.data?.message || 'Failed to create store'
            );
            throw error; // Re-throw the error so the caller can handle it
        }
    };
    const updateStore = async (
        updatedStore: CreateAndEditStoreInputType
    ): Promise<void> => {
        setErrorMessage(null);
        try {
            await updateStoreMutation.mutateAsync(updatedStore);
        } catch (error: any) {
            // Error already handled in mutation
            setErrorMessage(
                error.response?.data?.message || 'Failed to update store'
            );
            throw error; // Re-throw the error so the caller can handle it
        }
    };

    const deleteStore = async (id: string): Promise<void> => {
        setErrorMessage(null);
        try {
            await deleteStoreMutation.mutateAsync(id);
        } catch (error) {
            // Error already handled in mutation
        }
    };

    return (
        <StoreContext.Provider
            value={{
                stores: stores ?? [],
                isLoading,
                isFetchingError,
                errorMessage,
                createStore,
                updateStore,
                deleteStore,
                currentPage,
                setCurrentPage,
                pageSize,
                setPageSize
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export const useStoreContext = () => {
    const context = useContext(StoreContext);
    if (!context) {
        throw new Error('useStoreContext must be used within a StoreProvider');
    }
    return context;
};
