import { useQuery } from 'react-query';
import { fetchUsedSensorsData } from '@/services';
import { useSession } from '@/contexts';

type QueryParams = {
    startDate: string;
    endDate: string;
    storeId?: string;
};

export function useUsedSensorsData(params: QueryParams) {
    const { session, user } = useSession();
    const { startDate, endDate, storeId } = params;

    if (!storeId) {
        throw new Error('No Store found');
    }

    // if (!user) {
    //     throw new Error('No user found');
    // }
    if (!session) {
        throw new Error('No session found');
    }

    const accessToken = session?.access_token;
    return useQuery(
        ['sensorUsageSystemsData', startDate, endDate, storeId], // Unique query key
        () =>
            fetchUsedSensorsData({
                startDate,
                endDate,
                storeId,
                accessToken
            }),
        {
            enabled: !!accessToken && !!storeId && !!user, // Only run if accessToken is available
            retry: 2, // Optional: retry up to 2 times if the request fails
            // staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
            // cacheTime: 10 * 60 * 1000 // Optional: cache the data for 10 minutes
            staleTime: Infinity, // Mark the data as always fresh, no need for automatic refetch
            refetchOnWindowFocus: false, // Disable refetch when the window gains focus
            refetchOnReconnect: false, // Disable refetch when the network reconnects
            refetchInterval: false // Disable periodic refetch
        }
    );
}
