import { useTranslation } from 'react-i18next';
import {
    NumberOfSmartTagsChart,
    SmartTagsInUseChart,
    NumberOfSmartTagsHistoricalChart,
    SmartTagsInUseHistoricalChart
} from './charts';
import { DateRange } from 'react-day-picker';

function InventoryTab({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const { t } = useTranslation();

    return (
        <>
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-3 ">
                <div className="bg-white rounded-xl  mb-3  py-4 px-2">
                    <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 mt-2 ">
                        {t('analytics.numberOfSensors')}
                    </h1>
                    <NumberOfSmartTagsChart
                        dateRange={dateRange}
                        storeId={storeId}
                    />
                </div>
                <div className="bg-white rounded-xl  mb-3 py-4 px-2">
                    <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 mt-2">
                        {t('analytics.numberOfSensorsHistorical')}
                    </h1>
                    <NumberOfSmartTagsHistoricalChart
                        dateRange={dateRange}
                        storeId={storeId}
                    />
                </div>
            </div>

            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 sm:gap-3 ">
                <div className="bg-white rounded-xl  mb-3  py-4 px-2">
                    <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 mt-2 ">
                        {t('analytics.sensorsInUse')}
                    </h1>
                    <SmartTagsInUseChart
                        dateRange={dateRange}
                        storeId={storeId}
                    />
                </div>
                <div className="bg-white rounded-xl  mb-3 py-4 px-2">
                    <h1 className="text-xl font-semibold text-neutral-600 text-center md:text-left md:px-4 mt-2">
                        {t('analytics.sensorsInUseHistorical')}
                    </h1>
                    <SmartTagsInUseHistoricalChart
                        dateRange={dateRange}
                        storeId={storeId}
                    />
                </div>
            </div>
        </>
    );
}

export default InventoryTab;
