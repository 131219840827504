import { useEffect, useState } from 'react';
import { Chart } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { createSensorsHistory } from '@/hooks/data/mock';
import { DatePickerWithPresets } from '@/components/ui/DatePicker';
import { subDays, startOfDay } from 'date-fns';
import { DateRange } from 'react-day-picker';

const totalHistoricalData = createSensorsHistory(
    ['Safer', 'Mini Safer', 'Fashion', 'Bottle', 'Spider'],
    80
);

function NumberOfSmartTagsHistoricalChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    interface SensorHistory {
        name: string;
        data: number[];
    }

    const [sensorData, setSensorData] = useState<{
        sensorsHistory: SensorHistory[];
        lastSixMonths: string[];
    }>({
        sensorsHistory: [],
        lastSixMonths: []
    });

    useEffect(() => {
        const totalHistoricalData = createSensorsHistory(
            ['Safer', 'Mini Safer', 'Fashion', 'Bottle', 'Spider'],
            80
        );

        setSensorData(totalHistoricalData);
    }, []);

    return (
        <div className="relative pt-2  ">
            {sensorData.lastSixMonths &&
                sensorData.lastSixMonths?.length > 0 && (
                    <Chart
                        type={'line'}
                        data={totalHistoricalData.sensorsHistory}
                        options={getChartOptions(
                            totalHistoricalData.lastSixMonths,
                            'line'
                        )}
                    />
                )}
        </div>
    );
}

export default NumberOfSmartTagsHistoricalChart;
