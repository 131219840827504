export default {
    common: {
        moreDetails: 'More details',
        noResultsFound: 'No results found',
        save: 'Save',
        cancel: 'Cancel',
        delete: 'Delete',
        edit: 'Edit',
        continue: 'Continue',
        search: 'Search',
        selectAnOption: 'Select an option',
        active: 'Active',
        inactive: 'Inactive',
        previous: 'Previous',
        next: 'Next',
        totalResults: 'Total results',
        selectStore: 'Select a store',
        allStores: 'All stores',
        submit: 'Submit'
    },
    login: {
        title: 'Sign in',
        toContinueTo: 'to continue to',
        email: 'Email',
        password: 'Password',
        login: 'Login',
        forgotPassword: 'Forgot password?',
        register: 'Register',
        rememberMe: 'Remember me',
        welcomeTo: 'Welcome to'
    },
    resetPassword: {
        title: 'Reset password',
        subtitle: 'Enter the email you used to login with.',
        email: 'Email',
        send: 'Send',
        backToLogin: 'Back to login',
        resetPassword: 'Reset Password',
        resetPasswordDesc: 'The user will be automatically notified by email.',
        userResetPasswordSuccessfully: 'Reset password email sent successfully'
    },
    checkInbox: {
        title: 'Check your inbox',
        subtitle: "We've sent you an email with a link to reset your password.",
        backToLogin: 'Back to login'
    },
    dashboardMenu: {
        analytics: 'Analytics',
        sensors: 'Smart Tags',
        'smart-tags': 'Smart Tags',
        detacherUnits: 'Checkouts',
        'detacher-units': 'Checkouts',
        stores: 'Stores',
        users: 'Users',
        wifiConfigs: 'Wifi Configs',
        'wifi-configs': 'Wifi Configs',
        feedbackSupport: 'Feedback & Support'
    },
    header: {
        logout: 'Logout',
        changePassword: 'Change password'
    },
    analytics: {
        totalSensors: 'Smart Tags',
        detacherUnits: 'Checkouts',
        openedToday: 'Opened today',
        lowBatteries: 'Low batteries',
        inventory: 'Inventory',
        checkouts: 'Checkouts',
        numberOfSensors: 'Number of Smart Tags',
        numberOfSensorsHistorical: 'Number of Smart Tags (historical)',
        sensorsInUse: 'Smart Tags in use',
        sensorsInUseHistorical: 'Smart Tags in use (historical)',
        assignedVsUsed: 'Assigned vs Used',
        totalGroupedSensorUsageOnPOS: 'Smart Tags usage on Checkout and APPs',
        totalGroupedSensorUsageOnCashiers: 'Smart Tags usage on All Checkout',
        sensorsUsedAtCashier: 'Smart Tags usage on all Checkout in total',
        sensorsUsedAtPOS: 'Sensors used at Checkout',
        totalSensorUsedOnCashiers: 'Total sensor used on Cashiers'
    },
    error: {
        error: 'Error',
        somethingWentWrong: 'Something went wrong',
        login_failed: 'Login failed',
        invalid_grant: 'Invalid user credentials',
        email_is_required: 'Email is required',
        invalid_email: 'Invalid email address',
        password_is_required: 'Password is required',
        password_must_be_at_least_8_characters_long:
            'Password must be at least 8 characters long',
        password_must_include_at_least_one_uppercase_letter:
            'Password must include at least one uppercase letter',
        password_must_include_at_least_one_lowercase_letter:
            'Password must include at least one lowercase letter',
        password_must_include_at_least_one_digit:
            'Password must include at least one digit',
        password_must_include_at_least_one_special_character:
            'Password must include at least one special character'
    },
    datePicker: {
        selectADateRange: 'Select a date range',
        pickADate: 'Pick a date',
        last7Days: 'Last 7 days',
        last30Days: 'Last 30 days',
        last90Days: 'Last 90 days',
        last365Days: 'Last 365 days',
        custom: 'Custom'
    },
    stores: {
        store: 'Store',
        newStore: 'New Store',
        editStore: 'Edit Store',
        storeName: 'Store Name',
        foreignId: 'Foreign ID',
        email: 'Email',
        manager: 'Manager',
        phoneNumber: 'Phone Number',
        storeAddress: 'Store Address',
        city: 'City',
        state: 'State',
        postcode: 'Postcode',
        country: 'Country',
        timezone: 'Timezone',
        EASGateType: 'EAS Gate',
        connectionType: 'Connection',
        registers: 'Registers',
        users: 'Users',
        status: 'Status',
        active: 'Active',
        inactive: 'Inactive',
        filterStores: 'Filter stores...',
        storeDetails: 'Store details',
        storeNameRequired: 'Store name is required',
        addressRequired: 'Address is required',
        managerRequired: 'Manager is required',
        selectState: 'Select state',
        selectCountry: 'Select country',
        selectCity: 'Select city',
        storeCreateSuccess: 'Store created successfully',
        storeUpdateSuccess: 'Store updated successfully',
        storeDeleteSuccess: 'Store deleted successfully',
        searchStore: 'Search store ...'
    },
    wifi: {
        newWifiConfig: 'New WIFI Config',
        editWifiConfig: 'Edit Config',
        deleteWifiConfig: 'Delete Wifi Config',
        configName: 'Config Name',
        password: 'Password',
        networkName: 'Network Name (SSID)',
        securityType: 'Security Type',
        securityKey: 'Security Key',
        encryptionMethod: 'Encryption Method',
        identity: 'Identity',
        ssid: 'SSID',
        stores: 'Stores',
        configNameRequired: 'Config name is required',
        networkNameRequired: 'Network name is required',
        wifiCreateSuccess: 'Wifi created successfully',
        wifiUpdateSuccess: 'Wifi updated successfully',
        wifiDeleteSuccess: 'Wifi deleted successfully',
        searchWifiConfig: 'Search wifi config ...'
    },
    detacherUnit: {
        name: 'Name',
        description: 'Description',
        foreignId: 'Foreign ID',
        automaticOpening: 'Automatic Opening',
        buttonActiveSeconds: 'Active Time',
        status: 'Status',
        sensitivity: 'Sensitivity',
        merchantId: 'Merchant ID',
        mode: 'Mode',
        store: 'Store',
        zone: 'Zone',
        type: 'Type',
        selectType: 'Select type',
        detacherUnitUpdateSuccessfully: 'Checkout updated successfully',
        searchDetacherUnit: 'Search Checkout ...',
        low: 'Low',
        medium: 'Medium',
        high: 'High',
        editDetacherUnit: 'Edit Checkout',
        selectZone: 'Select zone'
    },
    smartTag: {
        serialNumber: 'Serial Number',
        batteryLevel: 'Battery Level',
        tagId: 'Tag ID',
        tagType: 'Tag Type',
        lastReported: 'Last Reported',
        firmwareVersion: 'Firmware Version',
        searchSmartTag: 'Search Smart Tag ...',
        editSmartTag: 'Edit Smart Tag',
        smartTagUpdateSuccessfully: 'Smart Tag updated successfully'
    },
    user: {
        id: 'ID',
        email: 'Email',
        role: 'Role',
        selectRole: 'Select user permission',
        stores: 'Stores',
        status: 'Status',
        firstName: 'First Name',
        lastName: 'Last Name',
        fullName: 'Full Name',
        searchUser: 'Search user ...',
        editUser: 'Edit User',
        userUpdateSuccessfully: 'User updated successfully',
        newUser: 'New User',
        userCreateSuccessfully: 'User created successfully',
        userDeleteSuccessfully: 'User deleted successfully',
        active: 'Active',
        inactive: 'Inactive',
        verified: 'Verified',
        notEmailVerified: 'Email is not verified',
        createdTime: 'Created Time',
        'permission-basic-employee': 'Basic Employee',
        'permission-basic-employee-desc':
            'Only has access to the Smart Tags Openings in the Merchant App',
        'permission-supervisor': 'Supervisor',
        'permission-supervisor-desc':
            'Has all the permissions in both the Dashboard and Merchant App.',
        'permission-advanced-employee': 'Advanced Employee',
        'permission-advanced-employee-desc':
            'Has access to the Analytics tab in the Dashboard. Also has access to Smart Tag Openings and Product Mapping in the Merchant App',
        'permission-manager': 'Manager',
        'permission-manager-desc':
            'Has access to Analytics, Smart Tags and Checkouts in the Dashboard. They have all the permissions in the Merchant App',
        'permission-technician': 'Technician',
        'permission-technician-desc':
            'Only has access to the Checkouts in the Dashboard.'
    },
    message: {
        areYouSure: 'Are you absolutely sure?',
        deleteStore:
            'This action cannot be undone. This will permanently delete the store.',
        deleteWifiConfig:
            'This action cannot be undone. This will permanently delete the wifi config.',
        deleteUser:
            'This action cannot be undone. This will permanently delete the user.'
    },
    filter: {
        filterBy: 'Filter by',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        permission: 'Permission',
        serialNumber: 'Serial Number',
        deviceId: 'Tag ID'
    },
    '403': {
        title: 'Access denied',
        description: 'You are not authorized to access this page.'
    },
    '404': {
        title: 'Page not found',
        description: 'The page you are looking for does not exist.',
        goBack: 'Go back'
    }
} as const;
