import { useQuery } from 'react-query';
import { useSession, useStoreContext } from '@/contexts';
import { serviceAPI } from '@/utils';

type QueryParams = {
    startDate: string;
    endDate: string;
    storeId: string | undefined;
};

export function useTotalSensorsData(params: QueryParams) {
    const { session, user } = useSession();
    const { startDate, endDate, storeId } = params;

    if (!storeId) {
        throw new Error('No store found');
    }
    if (!session) {
        throw new Error('No session found');
    }

    const accessToken = session?.access_token;
    return useQuery(
        ['sensorUsageSystemsData', startDate, endDate, storeId], // Unique query key
        async () => {
            const response = await serviceAPI('analytic').get(
                `/storeSensors?start_date=${startDate}&end_date=${endDate}&store_id=${storeId}`
            );

            if (response.status !== 200) {
                throw new Error('Failed to fetch Merchant Data');
            }

            const sensorValues = [
                {
                    data: [
                        response.data.Fashion_Sensors,
                        response.data.Bottle_Sensors,
                        response.data.Safer_Sensors,
                        response.data.Mini_Safer_Sensors,
                        response.data.Spider_Sensors,
                        response.data.Other_Sensors
                    ]
                }
            ];

            return { sensorValues, ...response };
        },
        {
            enabled: !!accessToken && !!user, // Only run if accessToken is available
            retry: 2, // Optional: retry up to 2 times if the request fails
            // staleTime: 5 * 60 * 1000, // Optional: data is considered fresh for 5 minutes
            // cacheTime: 10 * 60 * 1000, // Optional: cache the data for 10 minutes
            staleTime: Infinity, // Mark the data as always fresh, no need for automatic refetch
            refetchOnWindowFocus: false, // Disable refetch when the window gains focus
            refetchOnReconnect: false, // Disable refetch when the network reconnects
            refetchInterval: false, // Disable periodic refetch
            onError: (err: any) => {
                console.log('Error fetching data', err);
            }
        }
    );
}
