import React from 'react';
import { links } from './navItemsData';
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarRail,
    useSidebar
} from '@/components/ui/sidebar';
import CopyRight from '@/components/common/CopyRight';
import { VERSION_NUMBER } from '@/hooks/data/Constants';
import { NavMain } from './NavMain';
import { StoreType } from '@/types';

interface AppSidebarProps extends React.ComponentProps<typeof Sidebar> {
    merchantId: string;
    storesAccess: boolean;
    stores: StoreType[];
}

function AppSidebar({
    merchantId,
    storesAccess,
    stores = [],
    ...props
}: AppSidebarProps) {
    const { open } = useSidebar();

    return (
        <Sidebar collapsible="icon" {...props} className="bg-primary-600">
            <SidebarHeader className="flex justify-center items-center mb-8">
                <img
                    src={`./images/logo-w-${merchantId}.png`}
                    width={141}
                    height={34}
                    className=" w-24 h-auto"
                    alt="logo"
                />
            </SidebarHeader>
            <SidebarContent>
                <NavMain items={links} />
            </SidebarContent>
            <SidebarFooter>
                {open && <CopyRight version={VERSION_NUMBER} mode="light" />}
            </SidebarFooter>
            <SidebarRail />
        </Sidebar>
    );
}

export default AppSidebar;
