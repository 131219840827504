import { toast } from '@/hooks/useToast';
import { createContext, useContext, useCallback, useState } from 'react';

// Define the ErrorContext
const NotificationContext = createContext<{
    setGlobalError: (error: string) => void;
    setGlobalSuccess: (success: string) => void;
}>({ setGlobalError: () => {}, setGlobalSuccess: () => {} });

// ErrorProvider to wrap your app
export const NotificationProvider = ({
    children
}: {
    children: React.ReactNode;
}) => {
    const [notification, setNotification] = useState<string | null>(null);

    const setGlobalError = useCallback((errorMessage: string) => {
        if (errorMessage === 'Unauthorized') {
            return;
        }
        setNotification(errorMessage);

        // Show toast when error is set
        toast({
            title: 'Error',
            description: errorMessage,
            variant: 'error'
        });
    }, []);

    const setGlobalSuccess = useCallback((successMessage: string) => {
        setNotification(successMessage);

        // Show toast when error is set
        toast({
            title: 'Success',
            description: successMessage,
            variant: 'success'
        });
    }, []);

    return (
        <NotificationContext.Provider
            value={{ setGlobalError, setGlobalSuccess }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

// Hook for components to access global error handler
export const useGlobalNotification = () => useContext(NotificationContext);
