export default {
    common: {
        moreDetails: 'Mehr Details',
        noResultsFound: 'Keine Ergebnisse gefunden',
        save: 'Speichern',
        cancel: 'Abbrechen',
        delete: 'Löschen',
        edit: 'Bearbeiten',
        continue: 'Fortsetzen',
        search: 'Suche',
        selectAnOption: 'Wählen Sie eine Option',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        previous: 'Zurück',
        next: 'Weiter',
        totalResults: 'Gesamtergebnisse',
        selectStore: 'Wählen Sie ein Geschäft',
        allStores: 'Alle Geschäfte',
        submit: 'Einreichen'
    },
    login: {
        title: 'Anmelden',
        toContinueTo: 'um fortzufahren',
        email: 'E-Mail',
        password: 'Passwort',
        login: 'Anmelden',
        forgotPassword: 'Passwort vergessen?',
        register: 'Registrieren',
        rememberMe: 'Angemeldet bleiben',
        welcomeTo: 'Willkommen bei'
    },
    resetPassword: {
        title: 'Passwort zurücksetzen',
        subtitle:
            'Geben Sie die E-Mail-Adresse ein, mit der Sie sich angemeldet haben.',
        email: 'E-Mail',
        send: 'Senden',
        backToLogin: 'Zurück zur Anmeldung',
        resetPassword: 'Passwort zurücksetzen',
        resetPasswordDesc:
            'Der Benutzer wird automatisch per E-Mail benachrichtigt.',
        userResetPasswordSuccessfully:
            'Passwort zurücksetzen E-Mail erfolgreich gesendet'
    },
    checkInbox: {
        title: 'Überprüfen Sie Ihr Postfach',
        subtitle:
            'Wir haben Ihnen eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts gesendet.',
        backToLogin: 'Zurück zur Anmeldung'
    },
    dashboardMenu: {
        analytics: 'Analytik',
        sensors: 'Smart Tags',
        'smart-tags': 'Smart Tags',
        detacherUnits: 'Detacher Einheiten',
        'detacher-units': 'Detacher Einheiten',
        stores: 'Geschäfte',
        users: 'Benutzer',
        wifiConfigs: 'Wifi Konfigurationen',
        'wifi-configs': 'Wifi Konfigurationen',
        feedbackSupport: 'Feedback & Unterstützung'
    },
    header: {
        logout: 'Abmelden',
        changePassword: 'Passwort ändern'
    },
    analytics: {
        totalSensors: 'Sensoren',
        detacherUnits: 'Detacher Einheiten',
        openedToday: 'Heute geöffnet',
        lowBatteries: 'Niedrige Batterien',
        inventory: 'Inventar',
        checkouts: 'Checkouts',
        numberOfSensors: 'Anzahl der Sensoren',
        numberOfSensorsHistorical: 'Anzahl der Sensoren (historisch)',
        sensorsInUse: 'Sensoren in Benutzung',
        sensorsInUseHistorical: 'Sensoren in Benutzung (historisch)',
        assignedVsUsed: 'Zugewiesen vs. Benutzt',
        totalGroupedSensorUsageOnPOS:
            'Gesamte gruppierte Sensorverwendung an der Checkout',
        totalGroupedSensorUsageOnCashiers:
            'Gesamte gruppierte Sensorverwendung an den Kassierern',
        sensorsUsedAtCashier: 'Sensoren, die an der Kasse verwendet werden',
        sensorsUsedAtPOS: 'Sensoren, die an der Checkout verwendet werden',
        totalSensorUsedOnCashiers:
            'Insgesamt verwendeter Sensor an den Kassierern'
    },
    error: {
        error: 'Fehler',
        somethingWentWrong: 'Etwas ist schief gelaufen',
        login_failed: 'Anmeldung fehlgeschlagen',
        invalid_grant: 'Ungültige Benutzeranmeldeinformationen',
        email_is_required: 'E-Mail ist erforderlich',
        invalid_email: 'Ungültige E-Mail-Adresse',
        password_is_required: 'Passwort ist erforderlich',
        password_must_be_at_least_8_characters_long:
            'Passwort muss mindestens 8 Zeichen lang sein',
        password_must_include_at_least_one_uppercase_letter:
            'Passwort muss mindestens einen Großbuchstaben enthalten',
        password_must_include_at_least_one_lowercase_letter:
            'Passwort muss mindestens einen Kleinbuchstaben enthalten',
        password_must_include_at_least_one_digit:
            'Passwort muss mindestens eine Ziffer enthalten',
        password_must_include_at_least_one_special_character:
            'Passwort muss mindestens ein Sonderzeichen enthalten'
    },
    datePicker: {
        selectADateRange: 'Wählen Sie einen Datumsbereich',
        pickADate: 'Wählen Sie ein Datum',
        last7Days: 'Letzte 7 Tage',
        last30Days: 'Letzte 30 Tage',
        last90Days: 'Letzte 90 Tage',
        last365Days: 'Letzte 365 Tage',
        custom: 'Benutzerdefiniert'
    },
    stores: {
        store: 'Geschäft',
        newStore: 'Neues Geschäft',
        storeName: 'Geschäftsname',
        foreignId: 'Fremd-ID',
        email: 'E-Mail',
        manager: 'Manager',
        phoneNumber: 'Telefonnummer',
        storeAddress: 'Geschäftsadresse',
        city: 'Stadt',
        state: 'Bundesland',
        postcode: 'Postleitzahl',
        country: 'Land',
        EASGateType: 'EAS Gate',
        connectionType: 'Verbindungsart',
        registres: 'Registrierkassen',
        users: 'Benutzer',
        status: 'Status',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        filterStores: 'Geschäfte filtern...',
        storeDetails: 'Geschäftsdetails',
        storeNameRequired: 'Geschäftsname ist erforderlich',
        addressRequired: 'Adresse ist erforderlich',
        managerRequired: 'Manager ist erforderlich',
        selectState: 'Bundesland auswählen',
        selectCountry: 'Land auswählen',
        selectCity: 'Stadt auswählen',
        storeCreateSuccess: 'Geschäft erfolgreich erstellt',
        storeUpdateSuccess: 'Geschäft erfolgreich aktualisiert',
        storeDeleteSuccess: 'Geschäft erfolgreich gelöscht',
        searchStore: 'Geschäft suchen ...'
    },
    wifi: {
        newWifiConfig: 'Neue Wifi-Konfiguration',
        editWifiConfig: 'Wifi-Konfiguration bearbeiten',
        deleteWifiConfig: 'Wifi-Konfiguration löschen',
        configName: 'Konfigurationsname',
        password: 'Passwort',
        networkName: 'Netzwerkname',
        securityType: 'Sicherheitstyp',
        securityKey: 'Sicherheitsschlüssel',
        encryptionMethod: 'Verschlüsselungsmethode',
        identity: 'Identität',
        ssid: 'SSID',
        stores: 'Geschäfte',
        configNameRequired: 'Konfigurationsname ist erforderlich',
        networkNameRequired: 'Netzwerkname ist erforderlich',
        wifiCreateSuccess: 'Wifi-Konfiguration erfolgreich erstellt',
        wifiUpdateSuccess: 'Wifi-Konfiguration erfolgreich aktualisiert',
        wifiDeleteSuccess: 'Wifi-Konfiguration erfolgreich gelöscht',
        searchWifiConfig: 'Wifi-Konfiguration suchen ...'
    },
    detacherUnit: {
        name: 'Name',
        description: 'Beschreibung',
        foreignId: 'Fremd-ID',
        automaticOpening: 'Automatisches Öffnen',
        buttonActiveSeconds: 'Aktive Zeit',
        status: 'Status',
        sensitivity: 'Empfindlichkeit',
        merchantId: 'Händlernummer',
        mode: 'Modus',
        store: 'Geschäfts',
        zone: 'Zone',
        type: 'Typ',
        selectType: 'Typ auswählen',
        detacherUnitUpdateSuccessfully:
            'Detacher-Einheit erfolgreich aktualisiert',
        searchDetacherUnit: 'Detacher-Einheit suchen ...',
        low: 'Niedrig',
        medium: 'Mittel',
        high: 'Hoch',
        editDetacherUnit: 'Detacher-Einheit bearbeiten',
        selectZone: 'Zone auswählen'
    },
    smartTag: {
        serialNumber: 'Seriennummer',
        batteryLevel: 'Batteriestand',
        tagId: 'Tag-ID',
        tagType: 'Tag-Typ',
        lastReported: 'Zuletzt gemeldet',
        firmwareVersion: 'Firmware-Version',
        searchSmartTag: 'Smart Tag suchen ...',
        editSmartTag: 'Edit Smart Tag',
        smartTagUpdateSuccessfully: 'Smart Tag erfolgreich aktualisiert'
    },
    user: {
        id: 'ID',
        email: 'E-Mail',
        role: 'Rolle',
        selectRole: 'Benutzerberechtigung auswählen',
        stores: 'Geschäfte',
        status: 'Status',
        firstName: 'Vorname',
        lastName: 'Nachname',
        fullName: 'Vollständiger Name',
        searchUser: 'Benutzer suchen ...',
        editUser: 'Benutzer bearbeiten',
        userUpdateSuccessfully: 'Benutzer erfolgreich aktualisiert',
        newUser: 'Neuer Benutzer',
        userCreateSuccessfully: 'Benutzer erfolgreich erstellt',
        userDeleteSuccessfully: 'Benutzer erfolgreich gelöscht',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        verified: 'Verifiziert',
        notEmailVerified: 'E-Mail ist nicht verifiziert',
        createdTime: 'Erstellungszeit',
        'permission-basic-employee': 'Grundlegender Mitarbeiter',
        'permission-basic-employee-desc':
            'Hat nur Zugriff auf die Smart Tags Openings in der Händler-App',
        'permission-supervisor': 'Supervisor',
        'permission-supervisor-desc':
            'Verfügt über alle Berechtigungen sowohl im Dashboard als auch in der Merchant App.',
        'permission-advanced-employee': 'Fortgeschrittener Mitarbeiter',
        'permission-advanced-employee-desc':
            'Hat Zugriff auf die Registerkarte Analytics im Dashboard. Hat auch Zugriff auf Smart Tag Openings und Product Mapping in der Merchant App',
        'permission-manager': 'Manager',
        'permission-manager-desc':
            'Hat Zugriff auf Analytics, Smart Tags und Checkouts im Dashboard. Sie haben alle Berechtigungen in der Händler-App',
        'permission-technician': 'Techniker',
        'permission-technician-desc':
            'Hat nur Zugriff auf die Detacher-Einheiten im Dashboard.'
    },
    message: {
        areYouSure: 'Sind Sie absolut sicher?',
        deleteStore:
            'Diese Aktion kann nicht rückgängig gemacht werden. Dadurch wird der Speicher dauerhaft gelöscht.',
        deleteWifiConfig:
            'Diese Aktion kann nicht rückgängig gemacht werden. Dadurch wird die WLAN-Konfiguration dauerhaft gelöscht.',
        deleteUser:
            'Diese Aktion kann nicht rückgängig gemacht werden. Dadurch wird der Benutzer dauerhaft gelöscht.'
    },
    filter: {
        filterBy: 'Filtern nach',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        permission: 'Berechtigung',
        serialNumber: 'Seriennummer',
        deviceId: 'Tag-ID'
    },
    '403': {
        subTitle: 'Entschuldigung, Sie haben keinen Zugriff auf diese Seite.',
        description:
            'Sie haben keine Berechtigung, auf diese Seite zuzugreifen.'
    },
    '404': {
        subTitle: 'Entschuldigung, die Seite konnte nicht gefunden werden.',
        description: 'Die Seite, nach der Sie suchen, existiert nicht.',
        goBack: 'Zurück'
    }
} as const;
