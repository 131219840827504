import {
    AlertDialog as AlertDialogContainer,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';

function AlertDialog({
    open,
    setOpen,
    title,
    description,
    action,
    type = 'info'
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
    description: string;
    action: () => void;
    type?: 'info' | 'warning';
}) {
    const { t } = useTranslation();
    return (
        <AlertDialogContainer open={open} onOpenChange={setOpen}>
            <AlertDialogContent
                className={cn(
                    'bg-background',
                    type === 'warning' && 'bg-red-50'
                )}
            >
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {description}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
                    <AlertDialogAction
                        onClick={action}
                        className={cn(
                            'bg-primary-500',
                            type === 'warning' && 'bg-red-500'
                        )}
                    >
                        {t('common.continue')}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogContainer>
    );
}

export default AlertDialog;
