import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { UserCreateAndUpdateType, UserResponse, UserItemType } from '@/types';
import { useGlobalNotification, useSession, useStoreContext } from '@/contexts';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';

type ParamsType = {
    sort: 'asc' | 'desc' | '';
    filters: any;
    userId: string;
    storeId?: string;
};
export const useUser = (params: ParamsType) => {
    const queryClient = useQueryClient();
    const { setGlobalError, setGlobalSuccess } = useGlobalNotification();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { session, user } = useSession();
    const [currentPage, setCurrentPage] = useState(0); // Default to page 0
    const [pageSize, setPageSize] = useState(10); // Default to page size of 10

    //Fetch Users
    const {
        data: MerchantData = [],
        isLoading: MerchantDataIsLoading,
        isError: MerchantDataError
    } = useQuery(
        ['MerchantData'],
        async () => {
            const params: {
                merchantId?: string;
            } = {
                merchantId: user?.merchantId
            };
            const response = await serviceAPI('user').get(
                '/getAllStoresGroups',
                { params }
            );

            if (response.status !== 200) {
                throw new Error('Failed to fetch Merchant Data');
            }

            return response.data;
        },
        {
            enabled: !!session?.access_token,
            // staleTime: 5 * 60 * 1000,
            staleTime: Infinity,
            onError: (error: any) => {
                setErrorMessage(
                    error.response?.data?.error ||
                        'Failed to fetch Merchant Data'
                );
            }
        }
    );

    // Fetch Users
    const {
        data: accounts = {
            users: [],
            Total_Number_Of_Users: 0,
            available_permissions: []
        },
        isLoading,
        isError
    } = useQuery<UserResponse>(
        ['Users', params.storeId, pageSize, currentPage, params.filters],
        async () => {
            let queryString = `?merchantId=${user?.merchantId}&pageNumber=${currentPage}&numberOnPage=${pageSize}`;

            // Add optional parameters if they exist
            Object.entries(params).forEach(([key, value]) => {
                if (value !== undefined && value !== null && value !== '') {
                    queryString += `&${key}=${encodeURIComponent(value)}`;
                }
            });
            const response = await serviceAPI('user').get(queryString);
            if (
                response.request.responseURL.startsWith(
                    'https://auth-trunk.rapitag.com/auth/realms/users/protocol/openid-connect/auth'
                )
            ) {
                // handle the case where the user is not authenticated
                const error = new Error('Unauthorized');
                (error as any).status = 401; // Attach a 401 status
                throw error;
            }

            const data = {
                users: response.data.Users,
                Total_Number_Of_Users: response.data.Total_Number_Of_Users,
                available_permissions: response.data.available_permissions
            };

            return data;
        },
        {
            enabled: !!session?.access_token,
            staleTime: 5 * 60 * 1000,
            onError: (error: any) => {
                if (error.response?.status !== 401) {
                    setErrorMessage(
                        error.response?.data?.error ||
                            'Failed to fetch User Accounts'
                    );
                }
            }
        }
    );

    // Create User
    const createUser = useMutation(
        (newUser: UserCreateAndUpdateType): Promise<any> =>
            serviceAPI('user').post('', newUser),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['Users']);
                setGlobalSuccess(t('user.userCreateSuccessfully'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to create User'
                );
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to create User'
                );
            }
        }
    );

    // Update User
    const updateUser = useMutation(
        (updatedUser: UserCreateAndUpdateType): Promise<any> =>
            serviceAPI('user').put('', updatedUser),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(['Users']);
                setGlobalSuccess(t('user.userUpdateSuccessfully'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update User'
                );
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update User'
                );
            }
        }
    );

    // Delete User
    const deleteUser = useMutation(
        (userId: string): Promise<any> =>
            serviceAPI('user').delete(`/?userId=${userId}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['Users']);
                setGlobalSuccess(t('user.userDeleteSuccessfully'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to delete User'
                );
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to delete User'
                );
            }
        }
    );

    // Reset Password
    const resetPassword = useMutation(
        (userId: string): Promise<any> =>
            serviceAPI('user').get(`/resetPassword?userId=${userId}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['Users']);
                setGlobalSuccess(
                    t('resetPassword.userResetPasswordSuccessfully')
                );
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to reset password'
                );
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to reset password'
                );
            }
        }
    );

    return {
        accounts,
        isLoading,
        isError,
        errorMessage,
        createUser,
        updateUser,
        currentPage,
        deleteUser,
        setCurrentPage,
        pageSize,
        setPageSize,
        MerchantData,
        resetPassword
    };
};
