import { ApexOptions } from 'apexcharts';

const getChartOptions = (
    xaxis: string[],
    type: 'line' | 'bar' | 'pie' | 'donut' | 'area' | 'radar',
    horizontal?: boolean,
    group?: boolean
): ApexOptions => {
    const options: ApexOptions & {
        stroke?: {
            show?: boolean;
            width?: number;
            colors?: string[];
            curve?: string;
        };
    } = {
        //   colors: group ? [] : ["#FFC7C9", "#C1DFF6", "#FCE38B", "#E4EAE8", "#F7D7F2"],
        labels: xaxis,
        chart: {
            type: type,
            id: 'apexchart-example'
            // height: type === "donut" ? 100 : group ? 350 : 600,
            // width: 1200,
        },
        xaxis: {
            categories: xaxis
        },
        legend: {
            position: 'bottom',
            offsetY: 0
        },
        plotOptions: {
            bar: {
                borderRadius: group ? 3 : 5,
                distributed: !group,
                dataLabels: {
                    position: 'top'
                },
                horizontal: horizontal || false,
                columnWidth: group ? '100%' : '70%'
            },

            pie: {
                customScale: 0.8
            }
        },
        dataLabels: {
            // enabled: true,
            // formatter: function (val) {
            //     const value = (val as number).toFixed(0);
            //     return value + '%';
            // },
            // offsetY: -20,
            // offsetY: !group ? -20 : 0,
            offsetX: !group ? -20 : 0,
            textAnchor: 'middle',
            style: {
                fontSize: '13px',
                colors: ['#565656'],
                fontWeight: 'bold'
            },
            dropShadow: {
                enabled: false
            }
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.6
                }
            }
        },
        fill: {
            opacity: 1,
            colors: ['#FFC7C9', '#C1DFF6', '#FCE38B', '#E4EAE8', '#F7D7F2']
        }
    };

    if (type === 'line') {
        options['stroke'] = {
            curve: 'smooth'
        };
    } else {
        options['stroke'] = {
            show: true,
            width: 1,
            colors: ['transparent'],
            curve: 'smooth'
        };
    }
    return options;
};

export default getChartOptions;
