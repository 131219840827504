import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { ArrowUpDown, PencilIcon, TrashIcon, InfoIcon } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import clsx from 'clsx';
import { StoreType, WifiConfig, WifiConfigData } from '@/types';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from '@/components/ui/tooltip';

export const getWiFiColumns = (
    setSelectedRow: (v: string | null, action: 'edit' | 'delete') => void,
    deleteConfirmHandler: (v: boolean) => void,
    t: (key: string) => string,
    stores: StoreType[]
): ColumnDef<WifiConfig>[] => [
    {
        accessorKey: 'wifiConfigName',
        header: t('wifi.configName'),
        cell: ({ row }) => {
            const wifiConfigName = row.getValue('wifiConfigName');
            const wifi = row.original;

            return (
                <div className="flex flex-col items-start justify-start  ">
                    {wifiConfigName as string}
                    <span className="text-xs  text-neutral-400">
                        UUID: {wifi.id}
                    </span>
                </div>
            );
        }
    },
    {
        accessorKey: 'ssid',
        header: t('wifi.ssid')
    },
    {
        accessorKey: 'wifiConfigType',
        header: t('wifi.securityType'),
        cell: ({ row }) => {
            const wifiConfigType = row.getValue('wifiConfigType');

            return (
                <Badge
                    className={clsx(
                        'capitalize',
                        wifiConfigType === 'WPA2 Personal' &&
                            'bg-blue-100 text-blue-600',
                        wifiConfigType === 'WPA3 Personal' &&
                            'bg-green-100 text-green-700',
                        wifiConfigType === 'Public WiFi Configuration' &&
                            'bg-yellow-100 text-yellow-700',
                        !wifiConfigType && 'bg-gray-100 text-gray-700'
                    )}
                >
                    {wifiConfigType ? (wifiConfigType as string) : 'N/A'}
                </Badge>
            );
        }
    },

    {
        accessorKey: 'storeIds',
        header: t('wifi.stores'),
        cell: ({ row }) => {
            const storeIds: number[] = row.getValue('storeIds');

            return storeIds.length === 0 ? (
                'N/A'
            ) : (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger className="flex  items-center justify-start gap-2">
                            {storeIds?.length === 1
                                ? `${storeIds?.length} store`
                                : `${storeIds?.length} stores`}
                            <InfoIcon className="h-4 w-4 text-blue-500" />
                        </TooltipTrigger>
                        <TooltipContent>
                            <ol>
                                {storeIds?.map((id: number) => {
                                    const store = Array.isArray(stores)
                                        ? stores?.find(
                                              (st) =>
                                                  st.storeId == id?.toString()
                                          )
                                        : null;
                                    return (
                                        <li
                                            key={id}
                                            className="flex items-center gap-2"
                                        >
                                            <span>{store?.storeName}</span>
                                        </li>
                                    );
                                })}
                            </ol>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
    },

    {
        id: 'actions',
        cell: ({ row }) => {
            const wifi = row.original;

            return (
                <div className=" gap-1">
                    <Button
                        variant="ghost"
                        className="h-8 w-8 p-0"
                        onClick={() => {
                            setSelectedRow(wifi.id, 'edit');
                        }}
                    >
                        <span className="sr-only">Edit</span>
                        <PencilIcon className="h-4 w-4  text-slate-500" />
                    </Button>
                    <Button
                        variant="ghost"
                        className="h-8 w-8 p-0"
                        onClick={() => {
                            setSelectedRow(wifi.id, 'delete');
                            // deleteConfirmHandler(true);
                        }}
                    >
                        <span className="sr-only">Delete</span>
                        <TrashIcon className="h-4 w-4  text-slate-500" />
                    </Button>
                </div>
            );
        }
    }
];
