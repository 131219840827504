import { Button } from '@/components/ui/button';
import {
    Sheet,
    SheetContent,
    SheetHeader,
    SheetTitle
} from '@/components/ui/sheet';
import { useMediaQuery } from '@/hooks';
import { PlusIcon } from 'lucide-react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

function FormModal({
    btnLabel,
    formTitle,
    open,
    setOpen,
    children,
    hideCreateButton = false
}: {
    btnLabel?: string;
    formTitle: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    children: ReactElement;
    hideCreateButton?: boolean;
}) {
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const side = isDesktop ? 'right' : 'bottom';

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            {!hideCreateButton && (
                <Button
                    size="lg"
                    className="text-primary-50 hover:text-primary-100 hover:bg-primary-600 w-full md:w-auto h-14 "
                    onClick={() => setOpen(true)}
                >
                    <PlusIcon size={44} />
                    {btnLabel}
                </Button>
            )}
            <SheetContent
                side={side}
                className="mx-2 rounded-t-2xl md:mr-8 md:rounded-2xl md:h-fit inset-y-auto bottom-6 top-6  overflow-auto max-h-screen"
            >
                <SheetHeader>
                    <SheetTitle>{formTitle}</SheetTitle>
                </SheetHeader>
                {children}
            </SheetContent>
        </Sheet>
    );
}

export default FormModal;
