import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    useReactTable,
    getPaginationRowModel,
    SortingState,
    getSortedRowModel,
    ColumnFiltersState,
    getFilteredRowModel
} from '@tanstack/react-table';
import { ComboBoxResponsive } from '@/components/ui/combobox';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/components/ui/table';
import { TableSkeleton } from '@/components';

import { Pagination, SearchInput } from '@/components';
import { useTranslation } from 'react-i18next';

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    formComponent?: ReactElement;
    searchField?: string;
    searchPlaceholder?: string;
    pageSize: number;
    setPageSize: (v: number) => void;
    currentPage: number;
    setCurrentPage: (v: number) => void;
    totalCount?: number;
    searchOptions?: string[];
    setSearch?: (search: { type: string; value: string }) => void;
    search?: { type: string; value: string };
    onFilterApply?: () => void;
    setStore?: (storeId: string) => void;
    store?: string;
    loading?: boolean;
    storeOptions?: { value: string; label: string }[];
}

function DataTable<TData, TValue>({
    columns,
    data,
    formComponent,
    searchField,
    searchPlaceholder,
    pageSize = 10,
    setPageSize,
    currentPage = 0,
    setCurrentPage,
    totalCount,
    searchOptions = [],
    search,
    setSearch,
    onFilterApply,
    setStore,
    store,
    storeOptions,
    loading = false
}: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    // const { stores, isLoading: fetchStoresLoading } = useStoreContext();
    const { t } = useTranslation();

    const FormComponent = formComponent || null;
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            columnFilters
        },
        initialState: {
            pagination: {
                pageSize
            }
        }
    });

    const totalPages = Math.ceil(
        (totalCount ? totalCount : Array.isArray(data) ? data?.length : 0) /
            pageSize
    );
    return (
        <>
            <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-3 my-4 ">
                <div className="md:col-span-2 grid sm:grid-cols-1 lg:grid-cols-2 gap-3  ">
                    {/* <div className=" md:col-span-2  bg-red"> */}
                    <SearchInput
                        searchField={searchField}
                        searchPlaceholder={searchPlaceholder}
                        table={table}
                        searchOptions={searchOptions}
                        setSearch={setSearch}
                        onFilterApply={onFilterApply}
                        search={search}
                    />
                    {/* </div> */}

                    {storeOptions && setStore && (
                        <div className=" md:col-span-1 ">
                            <ComboBoxResponsive
                                onChange={(storeId) => {
                                    setStore(storeId!);
                                    setCurrentPage(0);
                                }}
                                statuses={storeOptions}
                                showPrefixIcon
                                className=" h-14   bg-white text-[16px] hover:bg-slate-50  px-3 rounded-lg md:max-w-[250px]"
                                value={store?.toString()}
                                placeholder={t('common.selectStore')}
                            />
                        </div>
                    )}
                </div>
                {!!FormComponent && (
                    <div className="md:col-end-8 md:col-span-1 ">
                        {FormComponent}
                    </div>
                )}
            </div>
            {loading ? (
                <TableSkeleton />
            ) : (
                <>
                    <div className="rounded-md border">
                        <Table>
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <TableHead key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                              header.column
                                                                  .columnDef
                                                                  .header,
                                                              header.getContext()
                                                          )}
                                                </TableHead>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {Array.isArray(table.getRowModel().rows) ? (
                                    table.getRowModel().rows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            data-state={
                                                row.getIsSelected() &&
                                                'selected'
                                            }
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => (
                                                    <TableCell key={cell.id}>
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns?.length}
                                            className="h-24 text-center"
                                        >
                                            No results.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <div className="flex items-center justify-end space-x-2 py-4">
                        <span className=" hidden md:block text-xs w-32 text-gray-400 absolute left-5">
                            {`${t('common.totalResults')}: ${
                                // data.length
                                totalCount
                                    ? totalCount
                                    : Array.isArray(data)
                                    ? data?.length
                                    : 0
                            }`}
                        </span>
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            table={table}
                        />
                    </div>
                </>
            )}
        </>
    );
}

export default DataTable;
