import { useLocation } from 'react-router-dom';
import { useSession } from '@/contexts';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList
} from '@/components/ui/breadcrumb';
import { Separator } from '@/components/ui/separator';
import { SidebarTrigger } from '@/components/ui/sidebar';
import { LogOut, User } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { useTranslation } from 'react-i18next';

function Header({ userName }: { userName: string }) {
    const { t } = useTranslation();
    const { signOut } = useSession();
    const location = useLocation();

    return (
        <header className="flex h-16 shrink-0 items-center justify-between gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
            <div className="flex items-center gap-2 px-4">
                <SidebarTrigger className="-ml-1" />
                <Separator
                    orientation="vertical"
                    className="mr-2 h-4 bg-primary-500"
                />
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem className="block">
                            <BreadcrumbLink href="#" className="uppercase">
                                {t(
                                    `dashboardMenu.${
                                        location.pathname.split('/')[1]
                                    }`
                                )}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>
            <div className="flex items-center gap-2 px-4">
                <p className="font-medium text-neutral-500">{userName}</p>
                {/* Dropdown */}
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="ghost"
                            className="w-8 h-8 rounded-full bg-primary-600 hover:bg-primary-200 text-white  hover:text-primary-600"
                        >
                            <User />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-42 mr-8">
                        <DropdownMenuItem onClick={signOut}>
                            <LogOut />
                            <span>{t('header.logout')}</span>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </header>
    );
}

export default Header;
