import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { SmartTagResponse } from '@/types';
import { useGlobalNotification, useSession } from '@/contexts';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';

type ParamsType = {
    deviceId?: string;
    serialNumber?: string;
    storeId?: string;
};

export const useSmartTag = ({
    deviceId,
    serialNumber,
    storeId
}: ParamsType) => {
    const queryClient = useQueryClient();
    const { setGlobalError, setGlobalSuccess } = useGlobalNotification();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { session, user } = useSession();
    const [currentPage, setCurrentPage] = useState(0); // Default to page 0
    const [pageSize, setPageSize] = useState(10); // Default to page size of 10

    // Fetch SmartTags
    const {
        data: SmartTags = {
            devices: [],
            totalCount: 0
        },
        isLoading,
        isError
    } = useQuery<SmartTagResponse>(
        ['SmartTags', storeId, pageSize, currentPage, deviceId, serialNumber],
        async () => {
            let query = `/battery_levels?numberOnPage=${pageSize}&pageNumber=${currentPage}&store_id=${storeId}`;
            if (!!deviceId) {
                query += `&deviceIds=${deviceId}`;
            } else if (!!serialNumber) {
                query += `&serialNumbers=${serialNumber}`;
            }

            const response = await serviceAPI('device').get(query);
            if (
                response.request.responseURL.startsWith(
                    'https://auth-trunk.rapitag.com/auth/realms/users/protocol/openid-connect/auth'
                )
            ) {
                // handle the case where the user is not authenticated
                const error = new Error('Unauthorized');
                (error as any).status = 401; // Attach a 401 status
                throw error;
            }

            return response.data;
        },
        {
            enabled: !!session?.access_token && !!user,
            // staleTime: 5 * 60 * 1000,
            staleTime: Infinity,
            onError: (error: any) => {
                console.log('error:', error);

                // if (error.response?.status !== 401) {
                //     setErrorMessage(
                //         error.response?.data?.error ||
                //             'Failed to fetch Smart Tags'
                //     );
                // }
            }
        }
    );

    // Update SmartTag
    const updateSmartTag = useMutation(
        (updatedSmartTag: {
            deviceId: string;
            storeId: number;
        }): Promise<any> =>
            serviceAPI('device').put(
                `/devices/stores/${updatedSmartTag.storeId}`,
                [updatedSmartTag.deviceId]
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['SmartTags']);
                setGlobalSuccess(t('smartTag.smartTagUpdateSuccessfully'));
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update Smart Tag'
                );
                setErrorMessage(
                    error.response?.data?.error || 'Failed to update Smart Tag'
                );
            }
        }
    );

    return {
        SmartTags,
        isLoading,
        isError,
        errorMessage,
        updateSmartTag,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize
    };
};
