import {
    ChartBar,
    Tag,
    Rss,
    ShoppingCart,
    Users,
    Wifi,
    LifeBuoy,
    type LucideIcon
} from 'lucide-react';

export type MenuItemType = {
    title: string;
    icon?: LucideIcon;
    url: string;
    isActive?: boolean;
    permission: string;
    // subItems?: ItemType[];
};

export const links: MenuItemType[] = [
    {
        title: 'analytics',
        url: '/analytics',
        icon: ChartBar,
        isActive: true,
        permission: 'usageAnalytics'
    },
    {
        title: 'stores',
        url: '/stores',
        icon: ShoppingCart,
        isActive: true,
        permission: 'storeManagement'
    },
    {
        title: 'sensors',
        url: '/smart-tags',
        icon: Tag,
        isActive: true,
        permission: 'sensorManagement'
    },
    {
        title: 'detacherUnits',
        url: '/detacher-units',
        icon: Rss,
        isActive: true,
        permission: 'appliances'
    },

    {
        title: 'users',
        url: '/users',
        icon: Users,
        isActive: true,
        permission: 'userManagement'
    },
    {
        title: 'wifiConfigs',
        url: '/wifi-configs',
        icon: Wifi,
        isActive: true,
        permission: 'wifiManagement'
    },
    {
        title: 'feedbackSupport',
        url: 'https://rapitag.zendesk.com/',
        icon: LifeBuoy,
        isActive: true,
        permission: 'feedbackSupport'

        //   subItems: [
        //     { title: "Templates", url: "/admin/templates" },
        //     { title: "Questions", url: "/admin/questions" },
        //     { title: "Settings", url: "/admin/settings" },
        //   ],
    }
];
