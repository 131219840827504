import { useQuery } from 'react-query';
import { fetchOverviewData } from '@/services';
import { useSession } from '@/contexts';

export function useOverviewData({ storeId }: { storeId: string | undefined }) {
    const { session, user } = useSession();

    if (!session) {
        throw new Error('No session found');
    }

    const accessToken = session?.access_token;
    return useQuery(
        ['overviewData', session?.access_token, storeId],
        async () => {
            if (
                session?.access_token &&
                storeId !== undefined &&
                storeId !== ' ' &&
                !isNaN(Number(storeId))
            ) {
                const response = await fetchOverviewData({
                    accessToken,
                    store_id: Number(storeId)
                });

                return response;
            }
        },
        {
            enabled: !!session?.access_token && storeId !== undefined && !!user,
            staleTime: Infinity, // Mark the data as always fresh, no need for automatic refetch
            refetchOnWindowFocus: false, // Disable refetch when the window gains focus
            refetchOnReconnect: false, // Disable refetch when the network reconnects
            refetchInterval: false, // Disable periodic refetch
            onError: (err) => {
                console.log('Error fetching data', err);
                // Optionally, setError((err as Error).message) if you have a state for errors
            }
        }
    );
}
