import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';

function BarChartSkeleton() {
    return (
        <div className="sm:h-[200px] md:h-[280px] lg:h-[360px]">
            <Skeleton className="h-[280px] w-full rounded-xl bg-slate-100 flex items-end justify-center pl-3 gap-2 ">
                <div className="flex flex-1 w-full rounded-xl bg-white" />
                <div className="h-[130px] flex-grow rounded-t-xl bg-white" />
                <div className="flex-1 w-full rounded-xl bg-white" />
                <div className="h-[230px] flex-grow rounded-t-xl bg-white" />
                <div className="flex-1 w-full rounded-xl bg-white" />
                <div className="h-[80px] flex-grow rounded-t-xl bg-white" />
                <div className="flex-1 w-full rounded-xl bg-white" />
                <div className="h-[100px] flex-grow rounded-t-xl bg-white" />
                <div className="flex-1 w-full rounded-xl bg-white" />
            </Skeleton>
            <div className="flex justify-center gap-3 mt-4">
                <Skeleton className="h-4 w-14 rounded-md bg-slate-100" />
                <Skeleton className="h-4 w-14 rounded-md bg-slate-100" />
                <Skeleton className="h-4 w-14 rounded-md bg-slate-100" />
                <Skeleton className="h-4 w-14 rounded-md bg-slate-100" />
            </div>
        </div>
    );
}

export default BarChartSkeleton;
