import { format } from 'date-fns';

const dateFormatter = (date: Date | undefined) => {
    if (!date) return '';
    const newDate = new Date(date);
    const isoFormat = format(newDate, "yyyy-MM-dd'T'HH:mm:ss");
    return isoFormat;
};

export default dateFormatter;
