import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import {
    PencilIcon,
    BadgeCheck,
    OctagonAlert,
    InfoIcon,
    CircleHelp,
    TrashIcon,
    KeyRound
} from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import clsx from 'clsx';
import { StoreType, UserItemType } from '@/types';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from '@/components/ui/tooltip';

import { format, parseISO } from 'date-fns';

export const getUserColumns = (
    setSelectedRow: (
        v: string | null,
        action: 'edit' | 'delete' | 'resetPassword'
    ) => void,
    t: (key: string) => string,
    stores: StoreType[] = []
): ColumnDef<UserItemType>[] => [
    {
        accessorKey: 'enabled',
        header: '',
        cell: ({ row }) => {
            const enabled = row.getValue('enabled');
            return (
                <div className="flex items-center justify-center w-9">
                    {enabled ? (
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger className="flex  items-center justify-start gap-2">
                                    <div className=" h-4 w-4 rounded-full bg-green-400  shadow-green  cursor-help" />
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('user.active')}
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    ) : (
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger className="flex  items-center justify-start gap-2  cursor-help">
                                    <div className="h-4 w-4 rounded-full bg-red-500" />
                                </TooltipTrigger>
                                <TooltipContent className="bg-red">
                                    {t('user.inactive')}
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    )}
                </div>
            );
        }
    },
    {
        accessorKey: 'firstName',
        header: t('user.fullName'),
        cell: ({ row }) => {
            const item = row.original;
            const verifyIcon = item.emailVerified ? (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger className="flex  items-center justify-start gap-2 cursor-help">
                            <BadgeCheck
                                strokeWidth={2.25}
                                className="h-4 w-4  text-green-600"
                            />
                        </TooltipTrigger>
                        <TooltipContent>{t('user.verified')}</TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger className="flex  items-center justify-start gap-2  cursor-help">
                            <OctagonAlert
                                strokeWidth={2.25}
                                className="h-4 w-4  text-orange-600"
                            />
                        </TooltipTrigger>
                        <TooltipContent className="bg-orange-600">
                            {t('user.notEmailVerified')}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
            return (
                <div className="flex flex-col items-start justify-start  ">
                    {item.firstName ?? ''} {item.lastName ?? ''}
                    <div className="flex  gap-2 justify-center  items-center">
                        <span className="text-sm  text-neutral-400">
                            {item.username}
                        </span>
                        {verifyIcon}
                    </div>
                </div>
            );
        }
    },

    {
        accessorKey: 'permission',
        header: t('user.role'),
        cell: ({ row }) => {
            const permission = row.getValue('permission');
            return (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger className="flex  items-center justify-start gap-2 cursor-help">
                            <div className="flex flex-row gap-2 ">
                                {t(`user.${permission}`)}
                                <CircleHelp className="h-4 w-4 text-blue-500" />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className="bg-blue-600">
                            {t(`user.${permission}-desc`)}
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
    },
    {
        accessorKey: 'attributes',
        header: t('user.stores'),
        cell: ({ row }) => {
            const attributes: {
                all_stores?: Record<number, string | null>;
            } = row.getValue('attributes');
            const assignedStores = attributes.all_stores!;
            const numberOfStores = Object.keys(assignedStores)?.length;
            return !numberOfStores ? (
                'N/A'
            ) : (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger className="flex  items-center justify-start gap-2 cursor-help ">
                            {numberOfStores === 1
                                ? `${numberOfStores} store`
                                : `${numberOfStores} stores`}
                            <InfoIcon className="h-4 w-4 text-blue-500" />
                        </TooltipTrigger>
                        <TooltipContent className="max-h-72 overflow-y-auto">
                            <ol>
                                {Object.keys(assignedStores).map((id) => {
                                    const store = Array.isArray(stores)
                                        ? stores?.find(
                                              (st) =>
                                                  st.storeId == id?.toString()
                                          )
                                        : null;
                                    return (
                                        <li
                                            key={id}
                                            className="flex items-center gap-2"
                                        >
                                            <span>
                                                {store?.storeName ?? 'N/A'}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ol>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            );
        }
    },
    {
        accessorKey: 'createdTimestamp',
        header: t('user.createdTime'),
        cell: ({ row }) => {
            const dateString = row.getValue('createdTimestamp');

            if (!dateString) {
                return (
                    <span className="text-[13px] text-neutral-400">N/A</span>
                );
            }
            const readableDate = format(
                new Date(+dateString.toString()),
                'MMM do, yyyy '
            );

            // const formattedDate = format(date, 'MMM do, yyyy HH:mm:ss');

            return (
                <span className="text-[13px] text-neutral-600">
                    {readableDate}
                </span>
            );
        }
    },
    {
        id: 'actions',
        cell: ({ row }) => {
            const item = row.original;

            return (
                <div className=" gap-1">
                    <Button
                        variant="ghost"
                        className="relative group   h-8 w-8 p-0 "
                        onClick={() => {
                            setSelectedRow(item.id, 'edit');
                        }}
                    >
                        {/* <span className="absolute bottom-full ml-2 opacity-0 md:group-hover:opacity-60 transition-opacity duration-1000 px-2 py-1 rounded-md bg-gray-700 text-white text-xs">
                            {t('common.edit')}
                        </span> */}
                        <PencilIcon className="h-4 w-4  text-slate-500" />
                    </Button>
                    <Button
                        variant="ghost"
                        className="relative group   h-8 w-8 p-0 "
                        onClick={() => {
                            setSelectedRow(item.id, 'delete');
                        }}
                    >
                        {/* <span className="absolute bottom-full ml-2 opacity-0  md:group-hover:opacity-60 transition-opacity duration-1000 px-2 py-1 rounded-md bg-gray-700 text-white text-xs">
                            {t('common.delete')}
                        </span> */}
                        <TrashIcon className="h-4 w-4  text-slate-500" />
                    </Button>
                    <Button
                        variant="ghost"
                        className="relative group   h-8 w-8 p-0 "
                        onClick={() => {
                            setSelectedRow(item.id, 'resetPassword');
                        }}
                    >
                        {/* <span className="absolute bottom-full ml-2 opacity-0  md:group-hover:opacity-60 transition-opacity duration-1000 px-2 py-1 rounded-md bg-gray-700 text-white text-xs">
                            {t('resetPassword.resetPassword')}
                        </span> */}
                        <KeyRound className="h-4 w-4  text-slate-500" />
                    </Button>
                </div>
            );
        }
    }
];
