import { BarChartSkeleton, Chart, Select } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import useSensorUsageCashiersData from '@/hooks/data/analytics/checkouts/useSensorUsageCashiersData';
// import { fetchSensorUsageCashiersData } from "@/app/lib/data/analytics/checkoutData";
import {
    // SMART_SENSORS_TYPES,
    SMART_TAGS_ITEMS
} from '@/hooks/data/Constants';
// import { SmartTagType } from "@/app/types";
// import Configs from "@/app/utils/config";
// import dateFormatter from "@/app/utils/dateFormatter";
import { useState } from 'react';
import { DatePickerWithPresets } from '@/components/ui/DatePicker';
import { dateFormatter } from '@/utils';
import { subDays, subSeconds } from 'date-fns';
import { DateRange } from 'react-day-picker';

function SmartTagsUsedAtPosChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const [sensorType, setSensorType] = useState<string>('fashion');

    const { data, error, isLoading } = useSensorUsageCashiersData({
        startDate: dateFormatter(dateRange!.from),
        endDate: dateRange?.to
            ? dateFormatter(subSeconds(dateRange.to, 1))
            : '',
        sensorType: sensorType,
        storeId
    });

    if (isLoading)
        return (
            <div className="mt-6">
                <BarChartSkeleton />
            </div>
        );
    if (error) return <p>Error</p>;

    return (
        <div className="relative pt-2 mt-6 ">
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 px-3 md:px-0  gap-y-2 mb-3 md:absolute top-[-12px] left-4 right-10 w-auto  md:w-full">
                <div className="grid sm:grid-cols-1 lg:grid-cols-2 w-auto    ">
                    <Select
                        items={SMART_TAGS_ITEMS}
                        onChange={setSensorType}
                        value={sensorType}
                    />
                </div>
            </div>

            <Chart
                type={'bar'}
                data={data?.values}
                options={getChartOptions(data?.cashierArray || [], 'bar', true)}
            />
        </div>
    );
}

export default SmartTagsUsedAtPosChart;
