import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import {
    PencilIcon,
    BatteryFull,
    BatteryLow,
    BatteryMedium
} from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import clsx from 'clsx';
import { StoreType, SmartTagItemType } from '@/types';

import { format, parseISO } from 'date-fns';

export const getSmartTagColumns = (
    setSelectedRow: (v: string | null, action: 'edit' | 'delete') => void,
    t: (key: string) => string,
    stores?: StoreType[]
): ColumnDef<SmartTagItemType>[] => [
    // {
    //     accessorKey: 'lastOnlineLabel',
    //     header: '',
    //     cell: ({ row }) => {
    //         const lastOnlineLabel = row.getValue('lastOnlineLabel');

    //         return (
    //             <div className="flex items-center justify-center">
    //                 {lastOnlineLabel ? (
    //                     <div className=" aspect-square w-2 rounded bg-green-400  shadow-green " />
    //                 ) : (
    //                     <div className="aspect-square w-2 rounded bg-neutral-200" />
    //                 )}
    //             </div>
    //         );
    //     }
    // },
    {
        accessorKey: 'serialNumber',
        header: t('smartTag.serialNumber'),
        cell: ({ row }) => {
            const item = row.original;

            return (
                <div className="flex flex-col items-start justify-start  ">
                    {item.serialNumber ?? 'N/A'}
                    <span className="text-xs  text-neutral-400">
                        {t('smartTag.tagId')}: {item.deviceName}
                    </span>
                </div>
            );
        }
    },
    {
        accessorKey: 'deviceType',
        header: t('smartTag.tagType'),
        cell: ({ row }) => {
            const deviceType: string = row.getValue('deviceType');

            return !deviceType ? (
                'N/A'
            ) : (
                <div className="flex items-center gap-2">
                    <span className=" capitalize">{deviceType}</span>
                </div>
            );
        }
    },

    {
        accessorKey: 'batteryLevel',
        header: t('smartTag.batteryLevel'),
        cell: ({ row }) => {
            const batteryLevel = row.getValue('batteryLevel') as number;
            const batteryOperationValue = batteryLevel - 3.3;
            const positiveBatteryOperationValue =
                batteryOperationValue > 0 ? batteryOperationValue : 0;
            let batteryPercentage = (positiveBatteryOperationValue / 0.7) * 100;
            const Icon =
                batteryLevel >= 3.6
                    ? BatteryFull
                    : batteryLevel >= 3.3
                    ? BatteryMedium
                    : BatteryLow;

            if (batteryPercentage > 100) {
                batteryPercentage = 100;
            }

            return (
                <Badge
                    className={clsx(
                        'capitalize flex flex-row items-center justify-center gap-1 w-24 bg-blue-200 text-blue-600',
                        batteryLevel <= 3.6 &&
                            batteryLevel > 3.3 &&
                            'bg-orange-200 text-orange-600',
                        batteryLevel < 3.3 && 'bg-red-200 text-red-600',
                        !batteryLevel && 'bg-gray-200 text-gray-600'
                    )}
                >
                    {!!batteryLevel && <Icon className="h-5 w-6" />}
                    {batteryLevel
                        ? `${String(batteryPercentage.toFixed(0))} %`
                        : 'N/A'}
                </Badge>
            );
        }
    },

    // {
    //     accessorKey: 'firmwareVersion',
    //     header: t('smartTag.firmwareVersion'),
    //     cell: ({ row }) => {
    //         const value = row.getValue('firmwareVersion') as [string, string];

    //         return value[0] ? (
    //             <span className="text-[13px] text-neutral-600">{value[0]}</span>
    //         ) : (
    //             <span className="text-[13px] text-neutral-400">N/A</span>
    //         );
    //     }
    // },

    {
        accessorKey: 'storeId',
        header: t('detacherUnit.store'),
        cell: ({ row }) => {
            const storeId: number = row.getValue('storeId');
            const store = Array.isArray(stores)
                ? stores?.find((st) => st.storeId == storeId?.toString())
                : null;
            return !store ? (
                'N/A'
            ) : (
                <div className="flex items-center gap-2">
                    <span>{store.storeName}</span>
                </div>
            );
        }
    },

    {
        accessorKey: 'timestamp',
        header: t('smartTag.lastReported'),
        cell: ({ row }) => {
            const dateString = row.getValue('timestamp') as string;

            if (!dateString) {
                return (
                    <span className="text-[13px] text-neutral-400">N/A</span>
                );
            }

            const date = parseISO(dateString);
            const formattedDate = format(date, 'MMM do, yyyy HH:mm:ss');

            return (
                <span className="text-[13px] text-neutral-600">
                    {formattedDate}
                </span>
            );
        }
    },

    {
        id: 'actions',
        cell: ({ row }) => {
            const device = row.original;

            return (
                <div className=" gap-1">
                    <Button
                        variant="ghost"
                        className="h-8 w-8 p-0"
                        onClick={() => {
                            setSelectedRow(device.sensorId, 'edit');
                        }}
                    >
                        <span className="sr-only">Edit</span>
                        <PencilIcon className="h-4 w-4  text-slate-500" />
                    </Button>
                </div>
            );
        }
    }
];
