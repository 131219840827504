import { Chart, Select } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { SMART_SENSORS_TYPES } from '@/hooks/data/Constants';
// import { SMART_SENSORS_TYPES } from "@/app/lib/data/Constants";
import { createSensorsHistory } from '@/hooks/data/mock';
import { subDays, startOfDay } from 'date-fns';
import { DateRange } from 'react-day-picker';
import React, { useState } from 'react';
import { DatePickerWithPresets } from '@/components/ui/DatePicker';

function TotalSensorsUsedOnCashiersHistoricalChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const [cashier, setCashier] = useState('Kasse1');
    // const [sensorData, setSensorData] = useState({
    //   sensorValues: [],
    // });
    const CASHIER_ITEMS = [
        { label: 'Kasse1', value: 'Kasse1' },
        { label: 'Kasse2', value: 'Kasse2' },
        { label: 'Kasse3', value: 'Kasse3' },
        { label: 'Kasse4', value: 'Kasse4' },
        { label: 'Kasse5', value: 'Kasse5' }
    ];
    const totalHistoricalDataOnSingleCashier = createSensorsHistory(
        SMART_SENSORS_TYPES,
        60
    );

    return (
        <div className="relative pt-2 mt-6  ">
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-2 mb-3 md:absolute px-3 top-[-15px] left-1  w-full">
                <div className="grid sm:grid-cols-1 lg:grid-cols-2   ">
                    <Select
                        items={CASHIER_ITEMS}
                        onChange={setCashier}
                        value={cashier}
                    />
                </div>
            </div>
            <Chart
                type={'line'}
                data={totalHistoricalDataOnSingleCashier.sensorsHistory}
                options={getChartOptions(
                    totalHistoricalDataOnSingleCashier.lastSixMonths,
                    'line'
                )}
            />
        </div>
    );
}

export default TotalSensorsUsedOnCashiersHistoricalChart;
