import * as React from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { format, startOfDay, subDays } from 'date-fns';
import { Calendar } from '@/components/ui/calendar';
import {
    Popover,
    PopoverContent,
    PopoverTrigger
} from '@/components/ui/popover';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/components/ui/select';
import { cn } from '@/utils';
import { useTranslation } from 'react-i18next';

type DatePickerWithPresetsProps = {
    dateRange: DateRange | undefined;
    setDateRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
};

const presetItems = [
    { label: 'last7Days', value: '7' },
    { label: 'last30Days', value: '30' },
    { label: 'last90Days', value: '90' },
    { label: 'last365Days', value: '365' },
    { label: 'custom', value: 'custom' }
];

function DatePickerWithRange({
    dateRange,
    setDateRange
}: DatePickerWithPresetsProps) {
    const [range, setRange] = React.useState('30');
    const [openCalendar, setOpenCalendar] = React.useState(false);
    const { t } = useTranslation();

    const handleSelectDate = (value: string) => {
        setRange(value);
        if (value === 'custom') {
            setOpenCalendar(true);
        } else {
            setDateRange({
                from: subDays(startOfDay(new Date()), parseInt(value)),
                to: startOfDay(new Date())
            });
        }
    };

    return (
        <div className={cn('grid gap-2')}>
            <Popover
                open={openCalendar}
                onOpenChange={setOpenCalendar}
                modal={true}
            >
                <PopoverTrigger>
                    <Select onValueChange={handleSelectDate} value={range}>
                        <SelectTrigger className="w-full h-14 bg-white border-none rounded-lg hover:bg-slate-50  ">
                            <SelectValue
                                placeholder="Select a fruit"
                                className="h-18"
                            >
                                {dateRange?.from ? (
                                    dateRange.to ? (
                                        <div className="flex flex-col py-2 justify-center items-start">
                                            <div className="flex items-center">
                                                <CalendarIcon size={15} />
                                                <span className="ml-2">
                                                    {' '}
                                                    {range === '7'
                                                        ? t(
                                                              'datePicker.last7Days'
                                                          )
                                                        : range === '30'
                                                        ? t(
                                                              'datePicker.last30Days'
                                                          )
                                                        : range === '90'
                                                        ? t(
                                                              'datePicker.last90Days'
                                                          )
                                                        : range === '365'
                                                        ? t(
                                                              'datePicker.last365Days'
                                                          )
                                                        : t(
                                                              'datePicker.custom'
                                                          )}
                                                </span>
                                            </div>
                                            <span className="text-xs text-slate-500">
                                                {format(
                                                    dateRange.from,
                                                    'LLL dd, y'
                                                )}{' '}
                                                -{' '}
                                                {format(
                                                    dateRange.to,
                                                    'LLL dd, y'
                                                )}
                                            </span>
                                        </div>
                                    ) : (
                                        format(dateRange.from, 'LLL dd, y')
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {/* <SelectLabel>Fruits</SelectLabel> */}
                                {presetItems.map((item) => (
                                    <SelectItem
                                        key={item.value}
                                        value={item.value}
                                    >
                                        {t(`datePicker.${item.label}`)}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={dateRange?.from}
                        selected={dateRange}
                        onSelect={setDateRange}
                        numberOfMonths={2}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
}

export default DatePickerWithRange;
