import { Chart } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { createSensorsHistory } from '@/hooks/data/mock';
import { useState } from 'react';
import { DatePickerWithPresets } from '@/components/ui/DatePicker';
import { subDays, startOfDay } from 'date-fns';
import { DateRange } from 'react-day-picker';

function SmartTagsInUseHistoricalChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const usedHistoricalData = createSensorsHistory(
        ['Safer', 'Mini Safer', 'Fashion', 'Bottle', 'Spider'],
        80
    );

    return (
        <div className="relative pt-2  ">
            <Chart
                type={'line'}
                data={usedHistoricalData.sensorsHistory}
                options={getChartOptions(
                    usedHistoricalData.lastSixMonths,
                    'line'
                )}
            />
        </div>
    );
}

export default SmartTagsInUseHistoricalChart;
