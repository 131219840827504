import React from 'react';
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious
} from '@/components/ui/pagination';

function PaginationComponent({
    currentPage,
    totalPages,
    setCurrentPage,
    table
}: {
    currentPage: number;
    totalPages: number;
    setCurrentPage: (page: number) => void;
    table: any;
}) {
    // Calculate the visible page range with the current page in the center
    const calculatePageRange = () => {
        const range = [];
        let startPage = Math.max(0, currentPage - 1); // Start page (1 before current page)
        let endPage = Math.min(totalPages - 1, currentPage + 1); // End page (1 after current page)

        // Adjust start page if there aren't enough pages before
        if (currentPage - startPage < 1) {
            startPage = Math.max(0, endPage - 2);
        }

        // Adjust end page if there aren't enough pages after
        if (endPage - currentPage < 1) {
            endPage = Math.min(totalPages - 1, startPage + 2);
        }

        for (let i = startPage; i <= endPage; i++) {
            range.push(i);
        }
        return range;
    };

    const visiblePages = calculatePageRange();
    return (
        <Pagination className="text-gray-500">
            <PaginationContent>
                {/* Previous button */}
                <PaginationItem>
                    <PaginationPrevious
                        title="Previous"
                        href="#"
                        onClick={() => {
                            if (currentPage > 0) {
                                setCurrentPage(currentPage - 1);
                                table.previousPage();
                            }
                        }}
                        className={
                            currentPage <= 0
                                ? 'pointer-events-none opacity-50'
                                : undefined
                        }
                    />
                </PaginationItem>

                {/* Ellipsis before the range (if necessary) */}
                {visiblePages[0] > 0 && (
                    <PaginationItem>
                        <PaginationLink
                            href="#"
                            onClick={() => {
                                setCurrentPage(0);
                                table.setPageIndex(0);
                            }}
                        >
                            1
                        </PaginationLink>
                    </PaginationItem>
                )}

                {visiblePages[0] > 1 && (
                    <PaginationItem>
                        <PaginationLink href="#">...</PaginationLink>
                    </PaginationItem>
                )}

                {/* Display visible page numbers */}
                {visiblePages.map((item) => (
                    <PaginationItem key={item}>
                        <PaginationLink
                            isActive={currentPage === item}
                            href="#"
                            onClick={() => {
                                setCurrentPage(item);
                                table.setPageIndex(item);
                            }}
                        >
                            {item + 1}
                        </PaginationLink>
                    </PaginationItem>
                ))}

                {/* Ellipsis after the range (if necessary) */}
                {visiblePages[visiblePages.length - 1] < totalPages - 1 && (
                    <PaginationItem>
                        <PaginationLink href="#">...</PaginationLink>
                    </PaginationItem>
                )}

                {visiblePages[visiblePages.length - 1] < totalPages - 1 && (
                    <PaginationItem>
                        <PaginationLink
                            href="#"
                            onClick={() => {
                                setCurrentPage(totalPages - 1);
                                table.setPageIndex(totalPages - 1);
                            }}
                        >
                            {totalPages}
                        </PaginationLink>
                    </PaginationItem>
                )}

                {/* Next button */}
                <PaginationItem>
                    <PaginationNext
                        title="Next"
                        href="#"
                        onClick={() => {
                            if (currentPage < totalPages - 1) {
                                setCurrentPage(currentPage + 1);
                                table.nextPage();
                            }
                        }}
                        className={
                            currentPage >= totalPages - 1
                                ? 'pointer-events-none opacity-50'
                                : undefined
                        }
                    />
                </PaginationItem>
            </PaginationContent>
        </Pagination>
    );
}

export default PaginationComponent;
