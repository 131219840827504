import { Chart } from '@/components';
import getChartOptions from '@/components/chart/chartOptions';
import { createSensorsHistory } from '@/hooks/data/mock';
import { DateRange } from 'react-day-picker';

function SmartTagsUsageOnAppPosHistoricalChart({
    dateRange,
    storeId
}: {
    dateRange: DateRange | undefined;
    storeId: string | undefined;
}) {
    const totalHistoricalDataOnAllCashiers = createSensorsHistory(
        ['Kasse1', 'Kasse2', 'Kasse3', 'Kasse4', 'Kasse5'],
        60
    );

    return (
        <div className="relative pt-2 ">
            <Chart
                type={'line'}
                data={totalHistoricalDataOnAllCashiers.sensorsHistory}
                options={getChartOptions(
                    totalHistoricalDataOnAllCashiers.lastSixMonths,
                    'line'
                )}
            />
        </div>
    );
}

export default SmartTagsUsageOnAppPosHistoricalChart;
