import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { ComboBoxResponsive } from '../ui/combobox';
import { useEffect, useState } from 'react';
import { useStoreContext } from '@/contexts';
import { FormModal } from '@/components';
import { useSmartTag } from '@/hooks';
import { SmartTagItemType } from '@/types';

const formSchema = z.object({
    storeId: z.string()
});

function SmartTagForm({
    data,
    openForm,
    setOpenForm
}: {
    data?: SmartTagItemType | undefined;
    openForm: boolean;
    setOpenForm: (open: boolean) => void;
}) {
    const { t } = useTranslation();
    const [transformedStoresList, setTransformedStoresList] = useState<
        {
            value: string;
            label: string;
        }[]
    >([]);

    const { updateSmartTag } = useSmartTag({});
    const { stores, isLoading } = useStoreContext();

    useEffect(() => {
        if (Array.isArray(stores) && stores?.length > 0) {
            const data = stores?.map((store) => ({
                value: store?.storeId?.toString(),
                label: store?.storeName
            }));
            if (data) {
                setTransformedStoresList(data);
            }
        }
    }, [stores]);

    // Set form values when the form is opened with data (update mode)
    useEffect(() => {
        if (data) {
            form.setValue('storeId', data.storeId?.toString());
        }
    }, [data, openForm]);

    // Reset form when the form is closed
    useEffect(() => {
        if (!openForm) {
            form.reset();
        }
    }, [openForm]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            storeId: ''
        }
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        const smartTagObj = {
            storeId: +values?.storeId,
            deviceId: data?.deviceName.toString() || ''
        };

        // Check if the store data has been updated (no changes detected)
        const isUpdated = Object.keys(values).some(
            (key) =>
                values[key as keyof typeof SmartTagForm] !==
                data![key as keyof SmartTagItemType]
        );
        if (!isUpdated) {
            setOpenForm(false);
            console.log('No changes detected');
            return;
        }
        updateSmartTag.mutate(smartTagObj, {
            onSuccess: () => {
                form.reset();
                setOpenForm(false);
            }
        });
    }

    return (
        <FormModal
            open={openForm}
            setOpen={setOpenForm}
            formTitle={t('smartTag.editSmartTag')}
            hideCreateButton={true}
        >
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4 mt-4"
                >
                    <FormField
                        control={form.control}
                        name="storeId"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={transformedStoresList}
                                    showPrefixIcon={false}
                                    placeholder={t('stores.selectState')}
                                    label={t('detacherUnit.store') + ' *'}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <Button
                        disabled={!!data && !form.formState.isDirty}
                        type="submit"
                    >
                        {t('common.submit')}
                    </Button>
                </form>
            </Form>
        </FormModal>
    );
}

export default SmartTagForm;
