import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { DetacherUnitType, DetacherUnitUpdateType } from '@/types';
import { useGlobalNotification, useSession, useStoreContext } from '@/contexts';
import { useTranslation } from 'react-i18next';
import { serviceAPI } from '@/utils';

export const useDetacherUnit = ({
    storeId
}: {
    storeId: string | undefined;
}) => {
    const queryClient = useQueryClient();
    const { setGlobalError, setGlobalSuccess } = useGlobalNotification();
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { session } = useSession();
    const [currentPage, setCurrentPage] = useState(0); // Default to page 0
    const [pageSize, setPageSize] = useState(10); // Default to page size of 10

    // Fetch DUs
    const {
        data: DetacherUnits = [],
        isLoading,
        isError
    } = useQuery<DetacherUnitType[]>(
        ['DetacherUnits', storeId],
        async () => {
            const detacherUnitsResponse = await serviceAPI('device').get(
                `/appliances/withOnline?store_id=${storeId}`
            );

            if (detacherUnitsResponse.status !== 200) {
                throw new Error('Failed to fetch DUs');
            }
            const detacherUnitsData = detacherUnitsResponse.data?.appliances;
            const lastOnlineData = detacherUnitsResponse.data?.logs;

            const zones = detacherUnitsResponse.data?.zones.map(
                (item: string, index: number) => {
                    return {
                        id: index,
                        name: item
                    };
                }
            );

            // Combine the two datasets
            const transformedData = detacherUnitsData.map(
                (unit: DetacherUnitType) => ({
                    ...unit,
                    zones,
                    lastOnline: lastOnlineData[unit.name]?.LastOnline || null,
                    lastOnlineLabel:
                        lastOnlineData[unit.name]?.LastOnlineLabel || false
                })
            );

            return transformedData;
        },
        {
            enabled: !!session?.access_token,
            // staleTime: 5 * 60 * 1000,
            staleTime: Infinity,
            onError: (error: any) => {
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to fetch DUs'
                );
            }
        }
    );

    // Update Checkout
    const updateDetacherUnit = useMutation(
        (updatedDetacherUnit: DetacherUnitUpdateType): Promise<any> =>
            serviceAPI('device').put(
                `/appliances/${updatedDetacherUnit.name}`,
                updatedDetacherUnit
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['DetacherUnits']);
                setGlobalSuccess(
                    t('detacherUnit.detacherUnitUpdateSuccessfully')
                );
            },
            onError: (error: any) => {
                setGlobalError(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update Checkout'
                );
                setErrorMessage(
                    error.response?.data?.error ??
                        error.response?.data?.Api_Message ??
                        'Failed to update Checkout'
                );
            }
        }
    );

    return {
        DetacherUnits,
        isLoading,
        isError,
        errorMessage,
        updateDetacherUnit,
        currentPage,
        setCurrentPage,
        pageSize,
        setPageSize
    };
};
