import { Country, State, City } from 'country-state-city';
import { Button } from '@/components/ui/button';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import FormModal from '../common/FormModal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Form, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { FloatingLabelInput } from '@/components/ui/floatingLabelInput';
import { ComboBoxResponsive } from '../ui/combobox';
import { useEffect, useState } from 'react';
import { StoreType } from '@/types';
import { FloatingLabelTextarea } from '../ui/FloatingTextarea';
import { useStoreContext } from '@/contexts';

const formSchema = z.object({
    storeName: z.string().min(2).max(50),
    foreignStoreId: z.string().max(20).optional(),
    manager: z.string().max(50).optional(),
    email: z.string().email(),
    phoneNumber: z.string().max(20).optional(),
    storeAddress: z.string().min(2).max(100),
    timezone: z.string().min(2).max(50),
    city: z.string().min(2).max(50),
    state: z.string().min(2).max(50),
    postcode: z.string().min(2).max(50),
    country: z.string().min(2).max(50)
});

function StoreForm({
    data,
    openForm,
    setOpenForm
}: {
    data?: StoreType | undefined;
    openForm: boolean;
    setOpenForm: (open: boolean) => void;
}) {
    const { t } = useTranslation();
    const [timezoneOptions, setTimezoneOptions] = useState<
        { value: string; label: string }[]
    >([]);
    const { createStore, updateStore } = useStoreContext();

    const countries = Country.getAllCountries();

    // Set form values when the form is opened with data (update mode)
    useEffect(() => {
        const storeCountry = countries.find(
            (country) => country.name === data?.country
        );

        const storeState = State.getStatesOfCountry(storeCountry?.isoCode).find(
            (st) => st.name === data?.state
        );

        const storeCity =
            storeCountry?.isoCode && storeState?.isoCode
                ? City.getCitiesOfState(
                      storeCountry?.isoCode,
                      storeState?.isoCode
                  ).find((city) => city.name === data?.city)?.name
                : null;

        if (data) {
            form.setValue('postcode', data.postcode);
            form.setValue('storeName', data.storeName);
            form.setValue('foreignStoreId', data.foreignStoreId || '');
            form.setValue('email', data.email);
            form.setValue('phoneNumber', data.phoneNumber);
            form.setValue('manager', data.manager);
            form.setValue('storeAddress', data.storeAddress);
            form.setValue('city', storeCity || '');
            form.setValue('state', storeState?.isoCode || '');
            form.setValue('country', storeCountry?.isoCode || 'DE');
            form.setValue(
                'timezone',
                data.timezone
                    ? storeCountry?.timezones?.find(
                          (tz) => tz.zoneName === data.timezone
                      )?.gmtOffsetName || ''
                    : ''
            );
        }
    }, [data]);

    // Reset form when the form is closed
    useEffect(() => {
        if (!openForm) {
            form.reset();
        }
    }, [openForm]);

    // Country
    const countriesComboList = countries.map((country) => ({
        value: country.isoCode,
        label: country.name,
        icon: country.flag
    }));

    // State
    const getStates = (isoCode: string) => {
        const states = State.getStatesOfCountry(isoCode);
        return states.map((state) => ({
            value: state.isoCode,
            label: state.name
        }));
    };

    // City
    const getCities = () => {
        const stateCode = State.getStatesOfCountry(countryValue).find(
            (state) => state.isoCode === stateValue
        )?.isoCode;

        if (!countryValue || !stateCode) return [];
        const cities = City.getCitiesOfState(countryValue, stateCode);
        return cities.map((city) => ({
            value: city.name,
            label: city.name
        }));
    };

    // Timezone
    const getTimezones = () => {
        const uniqueValues: string[] = [];
        const timezones =
            countries.find((country) => country.isoCode === countryValue)
                ?.timezones || [];

        return timezones
            .map((timezone) => ({
                value: timezone.gmtOffsetName,
                label: `${timezone.zoneName} - ${timezone.gmtOffsetName}`
            }))
            .filter((item) => {
                if (uniqueValues.includes(item.value)) return false;
                uniqueValues.push(item.value);
                return true;
            });
    };

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            storeName: '',
            foreignStoreId: '',
            email: '',
            phoneNumber: '',
            manager: '',
            storeAddress: '',
            city: '',
            state: '',
            postcode: '',
            country: 'DE',
            timezone: ''
        }
    });

    const countryValue = form.watch('country');
    const stateValue = form.watch('state');

    // reset other options when the country changes
    useEffect(() => {
        const country = countries.find(
            (country) => country.isoCode === countryValue
        );
        const newTimezones = getTimezones();
        setTimezoneOptions(newTimezones);
        if (country?.name !== data?.country) {
            if (!country) return;
            form.setValue('state', '');
            form.setValue('city', '');
            form.setValue('postcode', '');
            form.setValue('storeAddress', '');
            form.setValue(
                'timezone',
                country.timezones?.[0]?.gmtOffsetName || ''
            );
        }
    }, [countryValue]);

    function onSubmit(values: z.infer<typeof formSchema>) {
        // Do something with the form values.
        // ✅ This will be type-safe and validated.
        const storeObj = {
            ...values,
            country: countries.find(
                (country) => country.isoCode === values.country
            )?.name!,
            state: State.getStatesOfCountry(values.country).find(
                (state) => state.isoCode === values.state
            )?.name!,
            city: City.getCitiesOfState(values.country, values.state).find(
                (city) => city.name === values.city
            )?.name!,
            timezone: countries
                .find((country) => country.isoCode === values.country)
                ?.timezones?.find((tz) => tz.gmtOffsetName === values.timezone)
                ?.zoneName!
        };

        if (data) {
            // Check if the store data has been updated (no changes detected)
            const isUpdated = Object.keys(storeObj).some(
                (key) =>
                    storeObj[key as keyof typeof storeObj] !==
                    data[key as keyof StoreType]
            );

            if (!isUpdated) {
                setOpenForm(false);
                console.log('No changes detected');
                return;
            }

            updateStore({ ...storeObj, storeId: data.storeId }).then(() => {
                setOpenForm(false);
            });
        } else {
            createStore(storeObj).then(() => {
                setOpenForm(false);
            });
        }
    }

    return (
        <FormModal
            open={openForm}
            setOpen={setOpenForm}
            btnLabel={t('stores.newStore')}
            formTitle={!!data ? t('stores.editStore') : t('stores.newStore')}
        >
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-4 mt-4"
                >
                    <FormField
                        control={form.control}
                        name="storeName"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="storeName"
                                    label={t('stores.storeName') + ' *'}
                                    type="text"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="email"
                                    label={t('stores.email') + ' *'}
                                    type="email"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="foreignStoreId"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="foreignStoreId"
                                    label={t('stores.foreignId')}
                                    type="text"
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="country"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className=" flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={countriesComboList}
                                    label={t('stores.country') + ' *'}
                                    showPrefixIcon={false}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="state"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={getStates(countryValue)}
                                    showPrefixIcon={false}
                                    disabled={!countryValue}
                                    placeholder={t('stores.selectState')}
                                    label={t('stores.state') + ' *'}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="city"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    statuses={getCities()}
                                    showPrefixIcon={false}
                                    disabled={!stateValue}
                                    placeholder={t('stores.selectCity')}
                                    label={t('stores.city') + ' *'}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="storeAddress"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelTextarea
                                    {...field}
                                    id="storeAddress"
                                    label={t('stores.storeAddress') + ' *'}
                                    required
                                    rows={2}
                                    className="resize-none flex  w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-3 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="postcode"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="postcode"
                                    label={t('stores.postcode') + ' *'}
                                    type="text"
                                    required
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="timezone"
                        render={({ field }) => (
                            <FormItem>
                                <ComboBoxResponsive
                                    className="flex h-12 w-full border-gray-300 focus:border-primary-500	 
                  focus:ring-primary-500 rounded-lg   
                  bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 
                  file:bg-transparent file:text-sm file:font-medium file:text-foreground 
                  placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 
                  focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                                    // id="timezone"
                                    statuses={timezoneOptions}
                                    showPrefixIcon={false}
                                    disabled={timezoneOptions?.length < 2}
                                    label={t('stores.timezone') + ' *'}
                                    maxChars={60}
                                    {...field}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />

                    <FormField
                        control={form.control}
                        name="phoneNumber"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="phoneNumber"
                                    label={t('stores.phoneNumber')}
                                    type="tel"
                                    prefix={'+49'}
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="manager"
                        render={({ field }) => (
                            <FormItem>
                                <FloatingLabelInput
                                    {...field}
                                    id="manager"
                                    label={t('stores.manager')}
                                    type="text"
                                />
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button
                        className="w-full h-12 md:h-auto md:w-auto"
                        disabled={!!data && !form.formState.isDirty}
                        type="submit"
                    >
                        {t('common.submit')}
                    </Button>
                </form>
            </Form>
        </FormModal>
    );
}

export default StoreForm;
