import { UserType } from '@/types';
import { links } from '@/components/layout/navItemsData';

const checkRoutePermission = (route: string, user: UserType) => {
    const requiredPermission = links.find((item) => item.url === route);

    if (requiredPermission && user.permissions) {
        return user.permissions?.includes(requiredPermission.permission);
    }
    return false;
};

export default checkRoutePermission;
