import { Skeleton } from '@/components/ui/skeleton';

function TableSkeleton() {
    return (
        <div className={`flex h-[750px] flex-col  w-full  rounded-xl`}>
            {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-4 ">
                <Skeleton className="h-[50px]  rounded-xl bg-slate-100 lg:col-span-2" />
                <Skeleton className="h-[55px]  rounded-xl bg-slate-100" />
            </div> */}
            <Skeleton className="space-y-2  border-slate-100 border-2 rounded-xl p-4">
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                    <Skeleton className="h-12 flex-grow bg-slate-100" />
                </div>
                {/* <Skeleton className="h-4 w-[100px] bg-slate-100" />

                <Skeleton className="h-4 w-[200px]" /> */}
            </Skeleton>
        </div>
    );
}

export default TableSkeleton;
